import { useGlobalStore } from "@/store";
import theme from "@/theme";
import { getNumberOfDaysSince } from "@/utils";
import { Box, Fade, Stack } from "@mui/material";
import { useMachine } from "@xstate/react";
import { CVPFlowMachine, eventNames } from "./machine";
import { useEffect } from "react";
import SteppedProgressIndicator from "../SteppedProgressIndicator";
import HealthCheckAnimated from "./HealthCheckAnimated";
import HealthPlansAnimated from "./HealthPlansAnimated";
import HealthScoreAnimated from "./HealthScoreAnimated";
import MedicalAssistanceChatAnimated from "./MedicalAssistanceChatAnimated";
import Welcome from "./Welcome";
import { trackPageViewV2 } from "@/services/analytics-adapter";

interface CVPFlowProps {
  onContinue: (skipWelcomeScreen: boolean) => void;
  isSignUp: boolean;
}

export default function CVPFlow({ onContinue, isSignUp }: CVPFlowProps) {
  const { state, dispatch } = useGlobalStore();
  const [CVPFlowMachineState, CVPFlowMachineDispatch] = useMachine(
    CVPFlowMachine as any
  );

  function proceedToNextStep() {
    CVPFlowMachineDispatch({ type: eventNames.PROCEED_TO_NEXT_STEP });
  }

  function isLastStep() {
    return (
      CVPFlowMachineState.context.currentStepValue ===
      CVPFlowMachineState.context.totalSteps
    );
  }

  function isCurrentStepActive(stepNumber: number): boolean {
    return CVPFlowMachineState.context.currentStepValue === stepNumber;
  }

  function onFinalContinueButtonClick() {
    const skipWelcomePageIfCVPHasBeenViewed = state.hasCVPBeenViewed.value;

    if (!skipWelcomePageIfCVPHasBeenViewed) {
      onContinue(true);
    } else {
      onContinue(false);
    }

    dispatch({
      type: "SET_CVP_HAS_BEEN_VIEWED",
    });
  }

  useEffect(() => {
    const hasCVPBeenViewed = state.hasCVPBeenViewed.value;

    if (isSignUp === false) {
      const CVP_VIEW_CACHE_EXPIRY_DURATION_AS_DAYS = 30;
      const numberOfDaysSinceCVPWasViewed = getNumberOfDaysSince(
        new Date(state.hasCVPBeenViewed.createdAt)
      );
      const hasCVPBeenViewedRecently =
        numberOfDaysSinceCVPWasViewed < CVP_VIEW_CACHE_EXPIRY_DURATION_AS_DAYS;

      if (hasCVPBeenViewed && hasCVPBeenViewedRecently) {
        onFinalContinueButtonClick();
      }
    }

    if (!hasCVPBeenViewed || isSignUp) {
      trackPageViewV2({
        pageName: `CVP:${CVPFlowMachineState.value}`,
        pageSubSection1: "CVP",
        pageSubSection2: `CVP:${CVPFlowMachineState.value}`,
        pageCategory: "CVP",
      });
    }
  }, [CVPFlowMachineState]);

  useEffect(() => {
    const timeoutDelay = CVPFlowMachineState.context.transitionDuration * 1000;
    let timer: any = null;

    if (!isLastStep()) {
      timer = setTimeout(() => {
        proceedToNextStep();
      }, timeoutDelay);
    }

    return () => clearTimeout(timer);
  }, [CVPFlowMachineState.value]);

  return (
    <Stack
      flexGrow={1}
      minHeight="100%"
      sx={{ height: "100%", overflowX: "hidden", position: "relative" }}
    >
      <Box
        sx={{
          position: "absolute",
          left: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          top: (theme) => theme.spacing(3),
          zIndex: 2,
        }}
      >
        <SteppedProgressIndicator
          max={CVPFlowMachineState.context.totalSteps}
          value={CVPFlowMachineState.context.currentStepValue}
          animationDuration={CVPFlowMachineState.context.transitionDuration}
          animate={true}
          stepGap="medium"
        />
      </Box>

      <Fade
        in={isCurrentStepActive(1)}
        timeout={theme.transitions.duration.complex}
        style={{ height: "100%", position: "absolute", width: "100%" }}
      >
        <Box bgcolor="background.default">
          <HealthCheckAnimated
            onClick={proceedToNextStep}
            shouldAnimate={isCurrentStepActive(1)}
          />
        </Box>
      </Fade>

      <Fade
        in={isCurrentStepActive(2)}
        timeout={theme.transitions.duration.complex}
        style={{ height: "100%", position: "absolute", width: "100%" }}
      >
        <Box bgcolor="background.default">
          <HealthScoreAnimated
            onClick={proceedToNextStep}
            shouldAnimate={isCurrentStepActive(2)}
          />
        </Box>
      </Fade>

      <Fade
        in={isCurrentStepActive(3)}
        timeout={theme.transitions.duration.complex}
        style={{ height: "100%", position: "absolute", width: "100%" }}
      >
        <Box bgcolor="background.default">
          <MedicalAssistanceChatAnimated
            onClick={proceedToNextStep}
            shouldAnimate={isCurrentStepActive(3)}
          />
        </Box>
      </Fade>

      <Fade
        in={isCurrentStepActive(4)}
        timeout={theme.transitions.duration.complex}
        style={{ height: "100%", position: "absolute", width: "100%" }}
      >
        <Box bgcolor="background.default">
          <HealthPlansAnimated
            onClick={proceedToNextStep}
            shouldAnimate={isCurrentStepActive(4)}
          />
        </Box>
      </Fade>

      <Fade
        in={isCurrentStepActive(5)}
        timeout={theme.transitions.duration.complex}
        style={{ height: "100%", position: "absolute", width: "100%" }}
      >
        <Box bgcolor="background.default">
          <Welcome onClick={onFinalContinueButtonClick} />
        </Box>
      </Fade>
    </Stack>
  );
}
